<div class="page-content-container">
	<div class="page-content-header" data-cy="page-content-header">
		<ng-content select="[page-content-header]"></ng-content>
	</div>
	<div class="page-content-body">
		<div class="pane">
			<ng-content select="[page-content-body]"></ng-content>
		</div>
		<div class="sidepane">
			<ng-content select="[page-content-side]"></ng-content>
		</div>
	</div>
</div>
