<afm-page-content pane-center>
    <section page-content-body>
        <h1 page-content-header>STOR notifications</h1>
        <afm-spinner *ngIf="isLoading"></afm-spinner>
        <div [hidden]="isLoading" class="content-card">
            <a href="/stor/new" class="afm-button afm-button-secondary">New STOR</a>

            <mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="caseNumber">
                    <mat-header-cell *matHeaderCellDef>Case number</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.stor.caseNumber}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="notifier">
                    <mat-header-cell *matHeaderCellDef>Notifier</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.stor.notifier}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="registerDate">
                    <mat-header-cell *matHeaderCellDef>Register date</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{formatDate(row.stor.registerDate)}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let row">{{row.status}}</mat-cell>
                </ng-container>
                
                <ng-container matColumnDef="button">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row; let i = index">
                        <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                        <mat-menu #menu="matMenu">
                            <button *ngIf="row.editable" mat-menu-item (click)="edit(row)">
                                <mat-icon>edit</mat-icon>
                                <span>Edit</span>
                            </button>
                            <button *ngIf="row.editable" mat-menu-item (click)="deleteRow(row)">
                                <mat-icon>delete</mat-icon>
                                <span>Delete</span>
                            </button>
                            <button *ngIf="!row.editable" mat-menu-item (click)="downloadSummary(row)">
                                <mat-icon>download</mat-icon>
                                <span>Download</span>
                            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row class="selected-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data found</td>
                </tr>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>

        <p *ngIf="summaryFileDownloadFailed"><mat-error>The summary file could not be downloaded, it might not be available yet, please try again later.</mat-error></p>
    </section>
</afm-page-content>