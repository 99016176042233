<form [formGroup]="form">
  <h3>Natural Person</h3>
  <mat-form-field>
    <mat-label>Initials</mat-label>
    <input matInput formControlName="initials">
    <mat-error>
      <app-required-error [control]="form.controls['initials']"></app-required-error>
      <app-length-error [control]="form.controls['initials']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>First name(s)</mat-label>
    <input matInput formControlName="firstName">
    <mat-error>
      <app-length-error [control]="form.controls['firstName']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Last name prefix</mat-label>
    <input matInput formControlName="lastNamePrefix">
    <mat-error>
      <app-length-error [control]="form.controls['lastNamePrefix']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Last name(s)</mat-label>
    <input matInput formControlName="lastName">
    <mat-error>
      <app-required-error [control]="form.controls['lastName']"></app-required-error>
      <app-length-error [control]="form.controls['lastName']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Date of birth</mat-label>
    <input matInput formControlName="dateOfBirth" [matDatepicker]="picker" (dateChange)="fixDate($event.value)">
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error>
      <app-required-error [control]="form.controls['dateOfBirth']"></app-required-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>National Identification number</mat-label>
    <input matInput formControlName="nationalIdentificationNumber">
    <mat-error>
      <app-length-error [control]="form.controls['nationalIdentificationNumber']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <div>
    <app-info-dialog title="Information about the employment">
      <li>Place</li>
      <li>Position</li>
      <p>Information about the employment of the suspected person, from information sources available internally to the
        reporting entity (e.g. account documentation in case of clients, staff information system in case of an employee
        of the reporting entity).</p>
    </app-info-dialog>
  </div>
  <mat-form-field>
    <mat-label>Employer</mat-label>
    <input matInput formControlName="employer">
    <mat-error>
      <app-required-error [control]="form.controls['employer']"></app-required-error>
      <app-length-error [control]="form.controls['employer']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <div>
    <app-info-dialog title="Information about the employment">
      <li>Place</li>
      <li>Position</li>
      Information about the employment of the suspected person, from information sources available internally to the
      reporting entity (e.g. account documentation in case of clients, staff information system in case of an employee
      of the reporting entity).
    </app-info-dialog>
  </div>
  <mat-form-field>
    <mat-label>Position</mat-label>
    <input matInput formControlName="position">
    <mat-error>
      <app-length-error [control]="form.controls['position']"></app-length-error>
    </mat-error>
  </mat-form-field>
</form>
