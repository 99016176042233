import { Component } from '@angular/core';

@Component({
  selector: '[id=stor]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'stor';
}
