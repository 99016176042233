<form [formGroup]="form">
  <div class="details">
    <div class="details-content">
      <h3>Person to be contacted</h3>
      <app-info-dialog title="Contact for additional request for information">
        <p>Person to be contacted within the reporting entity for additional request for information relating to this
          report (e.g. compliance officer) and relevant contact details:</p>
        <ol type="a">
          <li>first name(s) and surname(s),</li>
          <li>position of the contact person within the reporting entity,</li>
          <li>professional e-mail address.</li>
        </ol>
      </app-info-dialog>
      <mat-form-field>
        <mat-label>Initials</mat-label>
        <input matInput formControlName="initials">
        <mat-error>
          <app-required-error [control]="form.controls['initials']"></app-required-error>
          <app-length-error [control]="form.controls['initials']"></app-length-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>First name(s)</mat-label>
        <input matInput formControlName="firstName">
        <mat-error>
          <app-length-error [control]="form.controls['firstName']"></app-length-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last name prefix</mat-label>
        <input matInput formControlName="lastNamePrefix">
        <mat-error>
          <app-length-error [control]="form.controls['lastNamePrefix']"></app-length-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last name(s)</mat-label>
        <input matInput formControlName="lastName">
        <mat-error>
          <app-required-error [control]="form.controls['lastName']"></app-required-error>
          <app-length-error [control]="form.controls['lastName']"></app-length-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Position</mat-label>
        <input matInput formControlName="position">
        <mat-error>
          <app-required-error [control]="form.controls['position']"></app-required-error>
          <app-length-error [control]="form.controls['position']"></app-length-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Email address</mat-label>
        <input matInput formControlName="emailAddress">
        <mat-error>
          <app-required-error [control]="form.controls['emailAddress']"></app-required-error>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phoneNumber">
      </mat-form-field>
    </div>
  </div>
</form>
