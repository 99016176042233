import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { SearchDialogComponent } from './search-dialog/search-dialog.component';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { DatetimeControlComponent } from './datetime-control/datetime-control.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { RequiredErrorComponent } from './required-error/required-error.component';
import { LengthErrorComponent } from './length-error/length-error.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { TextDialogComponent } from './info-dialog/text-dialog/text-dialog.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { MatRippleModule } from '@angular/material/core';
import { AfmPortaalCommonModule } from 'afm-portaal-common';
import AppConfigService from './services/app-config.service';
import { PageContentComponent } from './page-content/page-content.component';
@NgModule({
  imports: [
    CommonModule,
    MatStepperModule,
    FormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatRippleModule,
    AfmPortaalCommonModule
  ],
  declarations: [
    SearchDialogComponent,
    DatetimeControlComponent,
    RequiredErrorComponent,
    LengthErrorComponent,
    InfoDialogComponent,
    TextDialogComponent,
    SpinnerComponent,
    PageContentComponent,
  ],
  providers: [
    {
      provide: AppConfigService,
    },
  ],
  exports: [
    MatStepperModule,
    DatetimeControlComponent,
    RequiredErrorComponent,
    LengthErrorComponent,
    InfoDialogComponent,
    SpinnerComponent,
    PageContentComponent,
  ],
})
export class SharedModule {}
