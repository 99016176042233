import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TextDialogComponent } from './text-dialog/text-dialog.component';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent {
  @ViewChild('dialogContent') dialogContent!: ElementRef;
  @Input() title: string = "";

  constructor(public dialog: MatDialog) { }

  openInfoDialog(){
    this.dialog.open(TextDialogComponent, {
      data: {
        title: this.title,
        content: this.dialogContent.nativeElement,
      }
    });
  }
}
