<form [formGroup]="form">
  <section class="content-card">

    <h3>Description of financial instrument(s)</h3>

    <!-- financial instruments -->
    <div class="financialInstruments" formArrayName="financialInstruments">
      <mat-table #financialInstrumentsTable [dataSource]="financialInstruments.controls">
        <ng-container matColumnDef="issuer">
          <mat-header-cell *matHeaderCellDef>Issuer</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.value.issuer?.name ?? row.value.otherIssuer}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="isin">
          <mat-header-cell *matHeaderCellDef>Isin</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.value.isin}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cfi">
          <mat-header-cell *matHeaderCellDef>CFI code</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.value.cfiCode?.name ?? row.value.descriptionOtherCFI}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [disabled]="editableFinancialInstrument" (click)="editFinancialInstrument(i)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item [disabled]="editableFinancialInstrument" (click)="deleteFinancialInstrument(i)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="financialInstrumentsTableColumns"></mat-header-row>
        <mat-row class="selected-row" *matRowDef="let row; columns: financialInstrumentsTableColumns;"></mat-row>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            <p>No data</p>
          </td>
        </tr>
      </mat-table>

      <div *ngIf="editableFinancialInstrument" class="details">
        <div class="details-content">
          <app-financial-instrument [data]="data" [form]="editableFinancialInstrument"
            (confirm)="confirmFinancialInstrument()" (cancel)="cancelFinancialInstrument()"></app-financial-instrument>
        </div>
      </div>
      <button class="afm-button afm-button-secondary" (click)="newFinancialInstrument()"
        [disabled]="editableFinancialInstrument">New</button>
    </div>

    <div>
      <app-info-dialog title="Transaction(s) or Order(s) Start Date and Time en Transaction(s) or Order(s) End Date">
        <p>Date and time of transactions or orders that could constitute insider dealing, market manipulation or
          attempted insider dealing or market manipulation</p>
        <p>Indicate the date(s) and time(s) of the order(s) or transaction(s) specifying the time zone.</p>
      </app-info-dialog>
    </div>

    <h3>Transaction/Order</h3>
    <mat-form-field>
      <mat-label>Transaction/Order Start date time</mat-label>
      <input matInput [ngxMatDatetimePicker]="picker" formControlName="startDate" name="startDate"
        required>
      <ngx-mat-datetime-picker [showSeconds]='false' [showSpinners]="true" [stepHour]="1" [stepMinute]="1"
        [stepSecond]="1" [touchUi]="false" [enableMeridian]="false" #picker></ngx-mat-datetime-picker>
      <button type="button" mat-icon-button matSuffix class="mat-icon-button mat-button-base datepicker-btn"
        (click)="handleOpenPicker(picker, $event)">
        <mat-icon>today</mat-icon>
      </button>
      <mat-error>
        <app-required-error [control]="form.controls['startDate']"></app-required-error>
      </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Transaction/Order End date time</mat-label>
      <input matInput [ngxMatDatetimePicker]="picker2" formControlName="endDate" name="endDate">
      <ngx-mat-datetime-picker [showSeconds]='false' [showSpinners]="true" [stepHour]="1" [stepMinute]="1"
        [stepSecond]="1" [touchUi]="false" [enableMeridian]="false" #picker2></ngx-mat-datetime-picker>
      <button type="button" mat-icon-button matSuffix class="mat-icon-button mat-button-base datepicker-btn"
        (click)="handleOpenPicker(picker2, $event)">
        <mat-icon>today</mat-icon>
      </button>
    </mat-form-field>

    <div>
      <app-info-dialog title="Location (country)">
        <p>Full name of the country and the ISO 3166-1 two character country code, e.g. ‘The Netherlands – NL’.</p>
        Specify:
        <ol type="a">
          <li>where the order is given (if available);</li>
          <li>where the order is executed.</li>
        </ol>
      </app-info-dialog>
    </div>
    <label>Location (country) where Order is given</label>
    <p>
      <button class="afm-button afm-button-secondary" (click)="openRequesterCountrySearchDialog()">search</button>
      <span *ngIf="requesterCountry">{{requesterCountry.value?.name}}</span>
    </p>

    <div>
      <app-info-dialog title="Location (country)">
        <p>Full name of the country and the ISO 3166-1 two character country code, e.g. ‘The Netherlands – NL’.</p>
        Specify:
        <ol type="a">
          <li>where the order is given (if available);</li>
          <li>where the order is executed.</li>
        </ol>
      </app-info-dialog>
    </div>
    <label>Location (country) where Order is executed</label>
    <p>
      <button class="afm-button afm-button-secondary" (click)="openExecutorCountrySearchDialog()">search</button>
      <span *ngIf="executorCountry">{{executorCountry.value?.name}}</span>
    </p>

    <h3>Market</h3>

    <div class="markets" formArrayName="markets">
      <mat-table #marketsTable [dataSource]="markets.controls">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.value.market?.name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="mic">
          <mat-header-cell *matHeaderCellDef>MIC</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.value.market?.mic}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="country">
          <mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.value.market?.country}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="button">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row; let i = index">
            <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [disabled]="editableMarket" (click)="editMarket(i)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item [disabled]="editableMarket" (click)="deleteMarket(i)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="marketsTableColumns"></mat-header-row>
        <mat-row class="selected-row" *matRowDef="let row; columns: marketsTableColumns;"></mat-row>
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">
            <p>No data</p>
          </td>
        </tr>
      </mat-table>

      <div *ngIf="editableMarket" class="details">
        <div class="details-content">
          <app-market [data]="data" [form]="editableMarket" (confirm)="confirmMarket()"
            (cancel)="cancelMarket()"></app-market>
        </div>
      </div>
      <button class="afm-button afm-button-secondary" (click)="newMarket()" [disabled]="editableMarket">New</button>
    </div>


    <mat-checkbox formControlName="marketFound">Market found?</mat-checkbox>
    <mat-form-field *ngIf="!form.get('marketFound')?.value">
      <mat-label>Market description</mat-label>
      <input matInput formControlName="marketDescription">
      <mat-error>
        <app-required-error [control]="form.controls['marketDescription']"></app-required-error>
        <app-length-error [control]="form.controls['marketDescription']"></app-length-error>
      </mat-error>
    </mat-form-field>

    <div>
      <app-info-dialog title="Description of the order or transaction">
        Describe at least the following characteristics of the order(s) or the transaction(s) reported:
        <ol type="a">
          <li>transaction reference number/order reference number (where applicable),</li>
          <li>settlement date and time,</li>
          <li>purchase price/sale price,</li>
          <li>volume/quantity of financial instruments,</li>
          <p>Where there are multiple orders or transactions that could constitute insider dealing, market manipulation
            or
            attempted insider dealing or market manipulation, the details on the prices and volumes of such orders and
            transactions can be provided to the competent authority in an Annex to the STOR.</p>
          <li>information on the order submission, including at least the following:</li>
          <ul>
            <li>type of order (e.g. ‘buy with limit EUR x’),</li>
            <li>the way the order was placed (e.g. electronic order book),</li>
            <li>the timing when the order was placed,</li>
            <li>the person that actually placed the order,</li>
            <li>he person that actually received the order,</li>
            <li>the means by which the order is transmitted.</li>
          </ul>
          <li>information on the order cancellation or alteration (where applicable):</li>
          <ul>
            <li>the time of the alteration or cancellation,</li>
            <li>the person who altered or cancelled the order,</li>
            <li>the nature of the alteration (e.g. change in price or quantity) and the extent of the alteration,</li>
          </ul>
          <p>Where there are multiple orders or transactions, that could constitute insider dealing, market manipulation
            or
            attempted insider dealing or market manipulation, the details on the prices and volumes of such orders and
            transactions can be provided to the competent authority in an Annex to the STOR.</p>
          <li>the means to alter the order (e.g. via e-mail, phone, etc.).</li>
        </ol>
      </app-info-dialog>
    </div>
    <mat-form-field>
      <mat-label>Description of the Order or Transaction</mat-label>
      <textarea matInput formControlName="transactionDescription"></textarea>
      <mat-error>
        <app-length-error [control]="form.controls['transactionDescription']"></app-length-error>
      </mat-error>
    </mat-form-field>

  </section>
</form>