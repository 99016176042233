import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss']
})
export class TextDialogComponent implements AfterViewInit {
  @ViewChild('content') htmlContent!: ElementRef;
  content: any;
  title: string = "";

  constructor(
    public dialog: MatDialogRef<TextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    // this.htmlContent.nativeElement.appendChild(data.content);
    this.content = data.content;
    this.title = data.title;
  }

  ngAfterViewInit() {
    this.htmlContent.nativeElement.appendChild(this.content);
  }
}
