import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { StorData } from 'src/app/form/stor-data.model';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { ValidatorUtil } from 'src/app/util/validator-util';
@Component({
  selector: 'app-financial-instrument',
  templateUrl: './financial-instrument.component.html',
  styleUrls: ['./financial-instrument.component.scss']
})
export class FinancialInstrumentComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() data!: StorData;
  @Output() readonly confirm = new EventEmitter<void>();
  @Output() readonly cancel = new EventEmitter<void>();
  issuerFoundChanged!: Subscription;
  cfiCodeFoundChanged!: Subscription;
  otcChanged!: Subscription;

  get issuer(): AbstractControl {
    return this.form.controls['issuer'];
  }
  get cfiCode(): AbstractControl {
    return this.form.controls['cfiCode'];
  }


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.issuerFoundChanged = this.form.controls['issuerFound'].valueChanges.subscribe(e => {
      ValidatorUtil.setRequiredValidator(this.form.controls["otherIssuer"], !e);
      if (e) this.form.controls["otherIssuer"].reset();
    });
    this.cfiCodeFoundChanged = this.form.controls['cfiCodeFound'].valueChanges.subscribe(e => {
      ValidatorUtil.setRequiredValidator(this.form.controls["descriptionOtherCFI"], !e);
      if (e) this.form.controls["descriptionOtherCFI"].reset();
    });
    this.otcChanged = this.form.controls['otc'].valueChanges.subscribe(e => {
      ValidatorUtil.setRequiredValidator(this.form.controls["otcCharacteristics"], e);
      if (!e) this.form.controls["otcCharacteristics"].reset();
    });
    this.form.controls['issuerFound'].setValue(this.form.controls['issuerFound'].value);
    this.form.controls['cfiCodeFound'].setValue(this.form.controls['cfiCodeFound'].value);
    this.form.controls['otc'].setValue(this.form.controls['otc'].value);
  }

  ngOnDestroy(): void {
    this.issuerFoundChanged.unsubscribe();
    this.cfiCodeFoundChanged.unsubscribe();
    this.otcChanged.unsubscribe();
  }

  confirmFinancialInstrument() {
    if (this.form.valid) {
      this.confirm.emit();
    }
  }

  cancelFinancialInstrument() {
    this.cancel.emit();
  }

  openIssuerSearchDialog() {
    let columns = [
      { name: "name", label: "Name" },
      { name: "legalForm", label: "Legal form" },
      { name: "kvkNumber", label: "KvK number" },
      { name: "branchNumber", label: "Kvk branch number" },
      { name: "registeredOffice", label: "Registered office" }
    ]
    this.openSearchDialog(this.data.issuers, columns, this.issuer);
  }

  openCFISearchDialog() {
    let columns = [
      { name: "name", label: "Name" },
      { name: "cfiCategory", label: "CFI category" },
      { name: "cfiCategoryGroup", label: "CFI category group" },
      { name: "cfiCategoryDescription", label: "CFI category description" },
      { name: "cfiGroup", label: "CFI group" },
      { name: "cfiGroupDescription", label: "CFI group description" }
    ]
    this.openSearchDialog(this.data.cfiCodes, columns, this.cfiCode);
  }

  private openSearchDialog(data: any, columns: any, control: any) {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        rows: data,
        columns: columns,
        selectedRow: control.value
      },
      width: '75%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        control.patchValue(result);
    });
  }
}
