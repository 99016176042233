let baseRef:string;


export function getBaseRef(): string {
  return baseRef;
}


export function setBaseRef(ref:string) {
  baseRef = ref;
}