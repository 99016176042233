<section class="content-card">
  <ng-container>
    <mat-table #suspectsTable [dataSource]="suspects.controls">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.value['naturalPerson']?.lastName ?? row.value['legalPerson']?.companyName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="clientIdentifier">
        <mat-header-cell *matHeaderCellDef>clientIdentifier</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.value.clientIdentifier}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.value.country?.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="button">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [disabled]="editableSuspect"
              (click)="editSuspect(i)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button mat-menu-item [disabled]="editableSuspect"
              (click)="deleteSuspect(i)">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="suspectsTableColumns"></mat-header-row>
      <mat-row class="selected-row" *matRowDef="let row; columns: suspectsTableColumns;"></mat-row>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4"><p>No data</p></td>
      </tr>
    </mat-table>
    <div class="details">
      <div class="details-content">
        <app-suspect-form *ngIf="editableSuspect" [form]="editableSuspect" [data]="data"
          (confirm)="confirmSuspect()" (cancel)="cancelSuspect()"></app-suspect-form>
      </div>
    </div>
    <button class="afm-button" (click)="newSuspect()" [disabled]="editableSuspect">New</button>
  </ng-container>

</section>
