<section class="content-card">
<form [formGroup]="formGroup">
    Background or any other information considered relevant to the report.
    The following list is not exhaustive:
    <ul>
      <li>The position of the suspected person (e.g. retail client, institutions)</li>
      <li>The nature of the suspected entity’s/person’s intervention (on own account, on behalf of a client, other)</li>
      <li>The size of the suspected entity’s/person’s portfolio</li>
      <li>The date on which the business relationship with the client started if the suspected entity/person is a client
        of the reporting person/entity</li>
      <li>The type of activity of the trading desk, if available, of the suspected entity</li>
      <li>Trading patterns of the suspected entity/person. For guidance, the following are examples of information that
        may be useful:</li>
      <ul>
        <li>trading habits of the suspected entity/person in terms of use of leverage and short selling, and frequency
          of use</li>
        <li>comparability of the size of the reported order/transaction with the average size of the orders
          submitted/transactions carried out by the suspected entity/person for the past 12 months</li>
        <li>habits of the suspected entity/person in terms of the issuers whose securities it has traded or types of
          financial instruments traded for the past 12 months, in particular whether the reported order/transaction
          relates to an issuer whose securities have been traded by the suspected entity/person for the past year</li>
      </ul>
      <li>Other entities/persons known to be involved in the orders or transactions that could constitute insider
        dealing, market manipulation or attempted insider dealing or market manipulation:</li>
      <ul>
        <li>Names</li>
        <li>Activity (e.g. executing orders on behalf of clients, dealing on own account, operating a trading venue,
          systematic internaliser, etc.)</li>
      </ul>
    </ul>
    <mat-form-field>
      <mat-label>Additional information</mat-label>
      <textarea matInput formControlName="additionalInformation"></textarea>
      <mat-error>
        <app-length-error [control]="this.formGroup.controls['additionalInformation']"></app-length-error>
      </mat-error>
    </mat-form-field>
  </form>
</section>
