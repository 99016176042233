<div class="page-content-container" [attr.fullwidth]="true">
  <h1 class="page-content-header">
    <ng-container>Report Form STOR - Suspicious Transaction and Order Report</ng-container>
  </h1>
  <section *ngIf="!confirmed">
    <form [formGroup]="form" *ngIf="!isLoading">
      <mat-stepper [selectedIndex]="step" animationDuration="0" orientation="horizontal" linear="true" (selectionChange)="selectionChange()">

        <mat-step [completed]="step1Completed" [stepControl]="form.controls['contactInfo']">
          <ng-template matStepLabel><span class="page-content-header">Reporter</span></ng-template>
          <div class="page-content-body wizard-body">
            <section class="content-section">
              <h2>Identity of entity/person submitting the STOR</h2>
              <app-contact-info [form]="contactInfo" [account]="account"></app-contact-info>
            </section>
            <afm-stepper-button-section [showPreviousButton]="false" [disableNextButton]="!contactInfo.valid"></afm-stepper-button-section>
          </div>
        </mat-step>

        <mat-step [stepControl]="form.controls['transaction']">
          <ng-template matStepLabel><span class="page-content-header">Transaction</span></ng-template>
          <div class="page-content-body wizard-body">
            <section class="content-section">
              <h2>Transaction/Order</h2>
              <app-transaction [form]="transaction" [data]="data"></app-transaction>
            </section>
            <afm-stepper-button-section [disableNextButton]="!transaction.valid"></afm-stepper-button-section>
          </div>
        </mat-step>

        <mat-step [stepControl]="form.controls['natureOfSuspicion']">
          <ng-template matStepLabel><span class="page-content-header">Nature</span></ng-template>
          <div class="page-content-body wizard-body">
            <section class="content-section">
              <h2>Description of the nature of the suspicion</h2>
              <app-nature-of-suspicion [form]="natureOfSuspicion"
                [data]="data"></app-nature-of-suspicion>
            </section>
            <afm-stepper-button-section [form]="natureOfSuspicion" [disableNextButton]="!natureOfSuspicion.valid"></afm-stepper-button-section>
          </div>
        </mat-step>

        <mat-step [stepControl]="form.controls['identityOfSuspects']">
          <ng-template matStepLabel><span class="page-content-header">Suspect</span></ng-template>
          <div class="page-content-body wizard-body">
            <section class="content-section">
              <h2>Identity of the Suspected Person with respect to the orders or transactions</h2>
              <app-identity-of-suspect [formArray]="identityOfSuspects"
                [data]="data"></app-identity-of-suspect>
            </section>
            <afm-stepper-button-section [form]="identityOfSuspects" [disableNextButton]="!identityOfSuspects.valid"></afm-stepper-button-section>
          </div>
        </mat-step>

        <mat-step [stepControl]="form.controls['additionalInformation']">
          <ng-template matStepLabel><span class="page-content-header">Additional</span></ng-template>
          <div class="page-content-body wizard-body">
            <section class="content-section">
              <h2>Additional information</h2>
              <app-additional-information [formGroup]="additionalInformation"></app-additional-information>
            </section>
            <afm-stepper-button-section [disableNextButton]="!additionalInformation.valid"></afm-stepper-button-section>
          </div>
        </mat-step>

        <mat-step>
          <ng-template matStepLabel><span class="page-content-header">Documents</span></ng-template>
          <div class="page-content-body wizard-body">
            <section class="content-section">
              <h2>Documentation attached</h2>
              <app-documentation-attached [attachments]="attachments"></app-documentation-attached>
            </section>

            <mat-error *ngIf="submitFailed">Something when wrong when submitting you form, try again later or contact AFM.</mat-error>

            <afm-stepper-button-section [showNextButton]="false" [showSubmitButton]="true"
              (finalise)="submitForm()" [disableSubmitButton]="!form.valid"></afm-stepper-button-section>
          </div>
        </mat-step>

        <ng-template matStepperIcon="done" let-index="index">{{index + 1}}</ng-template>
        <ng-template matStepperIcon="edit" let-index="index">{{index + 1}}</ng-template>
      </mat-stepper>
    </form>
    <afm-spinner *ngIf="isLoading"></afm-spinner>
  </section>

  <div *ngIf="confirmed" class="page-content-body wizard-body">
    <section class="content-section">
      <app-confirmation></app-confirmation>
    </section>
  </div>

</div>
