import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-length-error',
  templateUrl: './length-error.component.html',
  styleUrls: ['./length-error.component.scss']
})
export class LengthErrorComponent {
@Input() control!: AbstractControl
get maxLengthError(){
  return this.control.errors?.['maxlength'];
}
}
