import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getBaseRef } from '../../util/mfeUtils';
import { AppConfig } from '../app-config.model';

@Injectable({
  providedIn: 'root',
})
export default class AppConfigService {
  public appConfig!: AppConfig;

  constructor(private httpClient: HttpClient) {}
  init = async () => {
    const baseRef = getBaseRef();
    this.appConfig = await firstValueFrom(
      this.httpClient.get<AppConfig>(`${baseRef}assets/config.json`)
    );
  };
}
