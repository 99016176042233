import { Injectable } from "@angular/core";
import { AccountPermission } from "../account-permission.model";

const ACTIVE_ACCOUNTID_STORAGE_KEY = 'activeAccountId';
const ACTIVE_ACCOUNT_STORAGE_KEY = 'activeAccount';
const ACCOUNTS_STORAGE_KEY = 'accounts';
const IDTOKEN_STORAGE_KEY = 'idToken';
const ACTIVEUSER_STORAGE_KEY = 'activeUser';
const EXTENSION_ACCOUNTPERMISSIONS = 'extension_accountPermissions';
const ACCOUNTPERMISSIONS_STORAGE_KEY = 'accountPermissions';

@Injectable({
	providedIn: 'root',
})
export class UserService {
  private sessionStorage = window.sessionStorage;

  get account(): any {
    const accountStr = this.sessionStorage.getItem('afm.portaal.activeAccount');
    if (accountStr) {
      return JSON.parse(accountStr);
    }
    return null;
  }

  get accountPermissions(): AccountPermission[] {
    const permStr = this.sessionStorage.getItem('afm.portaal.accountPermissions');
    if (permStr) {
      return JSON.parse(permStr);
    }

    return [];
  }

  get storFormName(): string{
    let userJson = window.sessionStorage.getItem('afm.portaal.activeUser');
    if(userJson){
      let activeUser = JSON.parse(userJson);
      return `storForm-${activeUser.userId}`;
    }
    return '';
  }
}
