import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { StorData } from 'src/app/form/stor-data.model';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { ValidatorUtil } from 'src/app/util/validator-util';

@Component({
  selector: 'app-nature-of-suspicion',
  templateUrl: './nature-of-suspicion.component.html',
  styleUrls: ['./nature-of-suspicion.component.scss']
})
export class NatureOfSuspicionComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup
  @Input() data!: StorData
  infringementTypeFoundChanged!: Subscription;

  get infringementType(): AbstractControl {
    return this.form.controls['infringementType'];
  }
  get isInfringementTypeRequired(): boolean {
    return this.form.controls['infringementType'].hasValidator(Validators.required);
  }

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.infringementTypeFoundChanged = this.form.controls['infringementTypeFound'].valueChanges.subscribe(e => {
      ValidatorUtil.setRequiredValidator(this.form.controls["otherInfringementType"], !e);
      if (e) this.form.controls["otherInfringementType"].reset();

      ValidatorUtil.setRequiredValidator(this.form.controls["infringementType"], e);
      if (!e) this.form.controls["infringementType"].reset();
    });
    this.form.controls['infringementTypeFound'].setValue(this.form.controls['infringementTypeFound'].value);
  }

  ngOnDestroy(): void {
      this.infringementTypeFoundChanged.unsubscribe();
  }

  openInfringementTypeSearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        rows: this.data.infringementTypes,
        columns: [
          { name: "name", label: "Name" },
          { name: "name_En", label: "Name ENG" },
        ],
        selectedRow: this.infringementType.value
      },
      width: '75%',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.infringementType.markAllAsTouched();
      if (result)
        this.infringementType.patchValue(result);
    });
  }
}
