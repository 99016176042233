import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-required-error',
  templateUrl: './required-error.component.html',
  styleUrls: ['./required-error.component.scss']
})
export class RequiredErrorComponent {
  @Input() control!: AbstractControl
}
