import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { ValidatorUtil } from 'src/app/util/validator-util';
import { StorData } from '../../stor-data.model';
import { Subscription } from 'rxjs';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
})
export class TransactionComponent implements OnInit, OnDestroy {
  @ViewChild('financialInstrumentsTable')
  financialInstrumentsTable!: MatTable<any>;
  @ViewChild('marketsTable') marketsTable!: MatTable<any>;
  @Input() form!: FormGroup;
  @Input() data!: StorData;
  financialInstrumentsTableColumns = ['issuer', 'isin', 'cfi', 'button'];
  marketsTableColumns = ['name', 'mic', 'country', 'button'];
  editableFinancialInstrument: FormGroup | null = null;
  editableMarket: FormGroup | null = null;
  oldFinancialInstrumentValue: any;
  oldMarketValue: any;
  marketFoundChanged!: Subscription;

  get financialInstruments(): FormArray {
    return this.form.get('financialInstruments') as FormArray;
  }

  get requesterCountry() {
    return this.form.get('requesterCountry') as FormControl;
  }

  get executorCountry() {
    return this.form.get('executorCountry') as FormControl;
  }

  get markets(): FormArray {
    return this.form.get('markets') as FormArray;
  }

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog) {}

  ngOnInit() {
    this.marketFoundChanged = this.form.controls[
      'marketFound'
    ].valueChanges.subscribe((e) => {
      ValidatorUtil.setRequiredValidator(
        this.form.controls['marketDescription'],
        !e
      );
      if (e) this.form.controls['marketDescription'].reset();
    });
    this.form.controls['marketFound'].setValue(
      this.form.controls['marketFound'].value
    );
  }

  ngOnDestroy() {
    this.marketFoundChanged.unsubscribe();
  }

  editFinancialInstrument(i: number) {
    this.editableFinancialInstrument = this.financialInstruments.at(
      i
    ) as FormGroup;
    this.oldFinancialInstrumentValue = this.financialInstruments.at(i).value;
  }

  deleteFinancialInstrument(i: number) {
    this.financialInstruments.removeAt(i);
    this.financialInstrumentsTable.renderRows();
  }

  confirmFinancialInstrument() {
    if (
      !this.financialInstruments.controls.some(
        (x) => x == this.editableFinancialInstrument
      )
    ) {
      this.financialInstruments.push(this.editableFinancialInstrument);
    }
    this.editableFinancialInstrument = null;
    this.financialInstrumentsTable.renderRows();
  }

  cancelFinancialInstrument() {
    this.editableFinancialInstrument?.patchValue(
      this.oldFinancialInstrumentValue
    );
    this.editableFinancialInstrument = null;
  }

  newFinancialInstrument() {
    this.editableFinancialInstrument = newFinancialInstrument(this.formBuilder);
  }

  openRequesterCountrySearchDialog() {
    this.openCountrySearchDialog(this.requesterCountry);
  }

  openExecutorCountrySearchDialog() {
    this.openCountrySearchDialog(this.executorCountry);
  }

  private openCountrySearchDialog(control: any) {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        rows: this.data.countries,
        columns: [
          { name: 'name', label: 'Naam' },
          { name: 'isoAlpha2Code', label: 'Iso 2 code' },
          { name: 'descriptionEn', label: 'Name' },
          { name: 'isoAlpha3Code', label: 'Iso 3 code' },
        ],
        selectedRow: control.value,
      },
      width: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) control.patchValue(result);
    });
  }

  editMarket(i: number) {
    this.editableMarket = this.markets.at(i) as FormGroup;
    this.oldMarketValue = this.markets.at(i).value;
  }

  deleteMarket(i: number) {
    this.markets.removeAt(i);
    this.marketsTable.renderRows();
  }

  confirmMarket() {
    if (!this.markets.controls.some((x) => x == this.editableMarket)) {
      this.markets.push(this.editableMarket);
    }
    this.editableMarket = null;
    this.marketsTable.renderRows();
  }

  cancelMarket() {
    this.editableMarket?.patchValue(this.oldMarketValue);
    this.editableMarket = null;
  }

  newMarket() {
    this.editableMarket = newMarket(this.formBuilder);
  }  

	handleOpenPicker(picker: any, event: any) {
		picker.open();
		event.stopPropagation();
	}
}

export function newFinancialInstrument(
  formBuilder: FormBuilder,
  value: any = null
) {
  let group = formBuilder.group({
    issuer: null,
    issuerFound: [true, Validators.required],
    otherIssuer: ['', Validators.maxLength(255)],
    isin: ['', Validators.maxLength(20)],
    cfiCode: null,
    cfiCodeFound: [true, Validators.required],
    descriptionOtherCFI: ['', Validators.maxLength(255)],
    cfiCodeAttributes: ['', Validators.maxLength(4)],
    otc: [false, Validators.required],
    otcCharacteristics: ['', Validators.maxLength(2000)],
    additionalInformation: ['', Validators.maxLength(5000)],
  });

  if (group) group.patchValue(value);

  return group;
}

export function newMarket(formBuilder: FormBuilder, value: any = null) {
  let group = formBuilder.group({
    country: null,
    market: null,
  });

  if (group) group.patchValue(value);

  return group;
}
