import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { StorData } from 'src/app/form/stor-data.model';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent {
  @Input() form!: FormGroup;
  @Input() data!: StorData;
  @Output() readonly confirm = new EventEmitter<void>();
  @Output() readonly cancel = new EventEmitter<void>();

  get market(): AbstractControl {
    return this.form.controls['market'];
  }
  get country(): AbstractControl {
    return this.form.controls['country'];
  }

  constructor(public dialog: MatDialog) { }

  confirmMarket() {
    if (this.form.valid) {
      this.confirm.emit();
    }
  }

  cancelMarket() {
    this.cancel.emit();
  }

  openMarketSearchDialog() {
    let data = this.country.value.name ? this.data.markets.filter(x => x.country === this.country.value.name) : this.data.markets;
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        rows: data,
        columns: [
          { name: "name", label: "Name" },
          { name: "mic", label: "MIC" },
          { name: "country", label: "Land" },
        ],
        selectedRow: this.market.value,
      },
      width: '75%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.market.patchValue(result);
    });
  }

  openCountrySearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        rows: this.data.countries,
        columns: [
          { name: "name", label: "Naam" },
          { name: "isoAlpha2Code", label: "Iso 2 code" },
          { name: "descriptionEn", label: "Name" },
          { name: "isoAlpha3Code", label: "Iso 3 code" },
        ],
        selectedRow: this.country.value
      },
      width: '75%',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result){
        this.country.patchValue(result);
      }
    });
  }
}
