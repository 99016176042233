<section class="content-card">
  <p>List the supporting attachments and material together provided with this STOR.</p>
  <p>Examples of such documentation are e-mails, recordings of conversations, order/transaction records,
    confirmations, broker reports, Powers of Attorney documents, and media comment where relevant. </p>
  <p>Where the detailed information about the orders/transactions referred to in Section 2 of this template is provided
    in a separate annex, indicate the title of that annex.</p>

  <div class="attachments-block" *ngFor="let attatchment of attachments; let i=index">
    <div class="attatchment">{{attatchment.fileName}}</div>
    <button class="afm-button" (click)="onDeleteButtonClick(i)">Delete</button>
  </div>

  <p *ngIf="totalSize > 31457280"><mat-error>Total size is more than 30MB</mat-error></p>
  <p *ngIf="hasInvalidFileExtention"><mat-error>One or more of the files has an invalid file extension and has not been
      uploaded</mat-error></p>
  <p *ngIf="hasInvalidFileName"><mat-error>One or more of the files names contains a non alphanumeric character and has
      not been uploaded</mat-error></p>

  <div>
    <button class="afm-button" variant="primary" (click)="onUploadButtonClick()" afm-button>Upload</button>
    <input #fileUpload type="file" [hidden]="true" [multiple]="true" (change)="onFileSelected($event)"
      [accept]="extensions" />

    <div>Supported extensions are: {{extensionsList}}.</div>
    <div>Only alphanumeric, dashes and underscores are accepted in the file names.</div>
    <div>The total size of all the files cannot not be larger than 30MB.</div>
  </div>
</section>
