import { Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-documentation-attached',
  templateUrl: './documentation-attached.component.html',
  styleUrls: ['./documentation-attached.component.scss']
})
export class DocumentationAttachedComponent {
  @ViewChild('fileUpload', { static: true }) fileUploadElement!: ElementRef<HTMLInputElement>;

  @Input() attachments!: { fileName: string, data: string, size: number }[];
  extensions: string[] = ['.xlsx', '.docx', '.txt', '.pdf', '.jpeg', '.png'];
  hasInvalidFileExtention: boolean = false;
  hasInvalidFileName: boolean = false;

  get extensionsList (){
    return this.extensions.map(x => x.substring(1));
  }

  get totalSize() {
    let size: number = 0
    this.attachments?.forEach(x => {
      size += x.size
    })
    return size;
  }

  onUploadButtonClick() {
    this.fileUploadElement.nativeElement.click();
  }

  onDeleteButtonClick(number: number) {
    this.attachments.splice(number, 1);
  }

  onFileSelected(event: any) {
    let files: FileList = event.target.files;

    this.hasInvalidFileName = false;
    this.hasInvalidFileExtention = false;

    for (let i = 0; i < files.length; i++) {
      let extension  = files[i].name.split('.').pop();
      let fileName = files[i].name.split('.')[0];

      if (this.extensions.some(x => x === `.${extension }`)) {
        if (/^[a-zA-Z0-9-_ ]+$/.test(fileName)) {

          new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onloadend = () => resolve(reader.result);
          }).then(result =>{
            let file = {
              fileName: files[i].name,
              size: files[i].size,
              data: result as string
            };
            this.attachments.push(file);
          });

        }
        else
          this.hasInvalidFileName = true;
      } else {
        this.hasInvalidFileExtention = true;
      }
    }
  }
}
