import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ApiService } from '../shared/services/api.service';
import { EncryptionService } from '../shared/services/encryption.service';
import { StorData } from './stor-data.model';
import { newSuspect } from './steps/step4/identity-of-suspect.component';
import {
  newFinancialInstrument,
  newMarket,
} from './steps/step2/transaction.component';
import { get, set } from 'idb-keyval';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../shared/services/user.service';
import { AuthService } from '../shared/services/auth.service';
import { AuthorisationTypeCode } from '../shared/authorisation-type-code.enum';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  step: number = 0;
  form!: FormGroup;
  attachments: { fileName: string; data: string; size: number }[] = [];
  data: StorData = {} as any;
  confirmed: boolean = false;
  isLoading: boolean = false;
  step1Completed: boolean = false;
  submitFailed: boolean = false;

  get sessionStorage(): Storage {
    return window.sessionStorage;
  }
  get localStorage(): Storage {
    return window.localStorage;
  }

  get contactInfo() {
    return this.form.get('contactInfo') as FormGroup;
  }
  get transaction() {
    return this.form.get('transaction') as FormGroup;
  }
  get natureOfSuspicion() {
    return this.form.get('natureOfSuspicion') as FormGroup;
  }
  get additionalInformation() {
    return this.form.get('additionalInformation') as FormGroup;
  }
  get identityOfSuspects() {
    return this.form.get('identityOfSuspects') as FormArray;
  }

  randomFormId: string = crypto.randomUUID();
  get formId() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) return id;
    return this.randomFormId;
  }

  get account() {
    return this.userService.account;
  }

  get isAuthorisedForStor() {
    return this.authService.isAuthorisedFor(AuthorisationTypeCode.STOR);
  }

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private encryptionService: EncryptionService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.buildForm();
    this.getFormFromLocalStorage();
  }

  loadData() {
    this.apiService
      .getIssuers()
      .subscribe((result) => (this.data.issuers = result));
    this.apiService
      .getCFICodes()
      .subscribe((result) => (this.data.cfiCodes = result));
    this.apiService
      .getCountries()
      .subscribe((result) => (this.data.countries = result));
    this.apiService
      .getMarkets()
      .subscribe((result) => (this.data.markets = result));
    this.apiService
      .getInfringementTypes()
      .subscribe((result) => (this.data.infringementTypes = result));
  }

  buildForm() {
    this.form = this.formBuilder.group({
      id: this.formId,
      contactInfo: this.formBuilder.group({
        account: this.userService.account.id as string,
        position: ['', Validators.maxLength(255)],
        isLoggedinUserFirstContact: true,
        firstContact: this.formBuilder.group({
          initials: ['', Validators.maxLength(20)],
          firstName: ['', Validators.maxLength(100)],
          lastNamePrefix: ['', Validators.maxLength(20)],
          lastName: ['', Validators.maxLength(100)],
          position: ['', Validators.maxLength(255)],
          emailAddress: ['', [Validators.maxLength(255), Validators.email]],
          phoneNumber: ['', Validators.maxLength(50)],
        }),
        hasSecondContact: false,
        secondContact: this.formBuilder.group({
          initials: ['', Validators.maxLength(20)],
          firstName: ['', Validators.maxLength(100)],
          lastNamePrefix: ['', Validators.maxLength(20)],
          lastName: ['', Validators.maxLength(100)],
          position: ['', Validators.maxLength(255)],
          emailAddress: ['', [Validators.maxLength(100), Validators.email]],
          phoneNumber: ['', Validators.maxLength(100)],
        }),
        actingCapacityOfEntity: ['', Validators.maxLength(255)],
        typeOfTradingActivity: ['', Validators.maxLength(200)],
        relationShipWithSuspect: ['', Validators.maxLength(255)],
      }),
      transaction: this.formBuilder.group({
        financialInstruments: this.formBuilder.array([]),
        startDate: [null, Validators.required],
        endDate: null,
        requesterCountry: null,
        executorCountry: null,
        markets: this.formBuilder.array([]),
        marketFound: [true, Validators.required],
        marketDescription: ['', Validators.maxLength(255)],
        transactionDescription: ['', Validators.maxLength(2000)],
      }),
      natureOfSuspicion: this.formBuilder.group({
        infringementType: [null, Validators.required],
        infringementTypeFound: true,
        otherInfringementType: ['', Validators.maxLength(255)],
        reasonForSuspicion: [
          '',
          [Validators.maxLength(5000), Validators.required],
        ],
      }),
      identityOfSuspects: this.formBuilder.array([]),
      additionalInformation: this.formBuilder.group({
        additionalInformation: ['', Validators.maxLength(6000)],
      }),
    });
  }

  selectionChange() {
    this.setFormInLocalStorage();
  }

  private getFormFromLocalStorage() {
    this.isLoading = true;
    get(this.userService.storFormName).then((result) => {
      this.encryptionService
        .decrypt(result)
        .then((x) => {
          if (x) {
            let form = x[this.formId];
            if (form) {
              this.form.patchValue(form.form);
              this.setFormArrays(form);
              this.attachments = form.attachments;
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
          let step = this.route.snapshot.paramMap.get('step');
          if (step) {
            this.step = Number(step);
            this.step1Completed = true;
          }
        });
    });
  }

  private setFormArrays(form: any) {
    (form.form.transaction.financialInstruments as any[]).forEach((x) => {
      (
        (this.form.get('transaction') as FormGroup).get(
          'financialInstruments'
        ) as FormArray
      ).push(newFinancialInstrument(this.formBuilder, x));
    });

    (form.form.transaction.markets as any[]).forEach((x) => {
      (
        (this.form.get('transaction') as FormGroup).get('markets') as FormArray
      ).push(newMarket(this.formBuilder, x));
    });

    (form.form.identityOfSuspects as any[]).forEach((x) => {
      (this.form.get('identityOfSuspects') as FormArray).push(
        newSuspect(this.formBuilder, x)
      );
    });
  }

  private setFormInLocalStorage() {
    get(this.userService.storFormName).then((result) => {
      this.encryptionService.decrypt(result).then((x) => {
        let form = {
          form: this.form.value,
          attachments: this.attachments,
        };
        if (x && x[this.formId]) {
          x[this.formId] = form;
        } else {
          x = {
            ...(x as any),
            [this.formId]: form,
          };
        }
        this.encryptionService
          .encrypt(x)
          .then((x) => {
            set(this.userService.storFormName, x);
          })
          .finally(() => {
            this.router.navigate(['/stor/edit', { id: this.formId, step: 1 }]);
          }); 
      });
    });
  }

  submitForm() {
    this.isLoading = true;
    if (this.form.valid) {
      let form = {
        form: this.form.value,
        attachments: this.attachments,
      };

      this.apiService.postStor(form).subscribe({
        next: () => {
          this.confirmed = true;
          this.isLoading = false;

          this.removeFromIndexDb(form.form.id);
        },
        error: (error) => {
          this.step = 5;
          this.submitFailed = true;
          console.error(error);
          this.isLoading = false;
        },
      });
    }
  }

  removeFromIndexDb(id: string) {
    get(this.userService.storFormName).then((result) => {
      this.encryptionService.decrypt(result).then((x) => {
        delete x[id];
        this.encryptionService.encrypt(x).then((x) => {
          set(this.userService.storFormName, x);
        });
      });
    });
  }
}
