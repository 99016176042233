<h2>Suspicious Transaction and Order Report</h2>

<div *ngIf="storItems?.length">
  <ng-container *ngFor="let rowItem of storItems; let i = index;">
    <div class="row" *ngIf="i < 3">
      {{rowItem.registerDate | date : "dd-MM-yyyy - HH:mm"}} <h3 class="notification-title">| {{rowItem.caseNumber}}</h3>
    </div>
  </ng-container>
</div>
<p *ngIf="storItems.length > 3" class="count">3 of {{totalStorItems}}</p>

<a [href]="storOverviewUrl" class="afm-button afm-button-secondary">View all</a>
