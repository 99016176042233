
<mat-form-field>
  <mat-label>{{dateLabel}}</mat-label>
  <input matInput [required]="isRequired" [matDatepicker]="picker" [(ngModel)]="date">
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
  <mat-error>
    <app-required-error [control]="formControl"></app-required-error>
  </mat-error>
</mat-form-field>

<label>{{timeLabel}}</label>

<div class="time-block">
<mat-form-field id="hour-field" class="time-field">
  <mat-label>hour</mat-label>
  <input type="text" matInput pattern="([0-5]+[0-9]{1}|[0-9]{1})" maxlength="2" [required]="isRequired" [(ngModel)]="hours">
  <mat-error>
    <app-required-error [control]="formControl"></app-required-error>
  </mat-error>
</mat-form-field>
<mat-form-field class="time-field">
  <mat-label>minute</mat-label>
  <input type="text" matInput pattern="([0-5]+[0-9]{1}|[0-9]{1})" maxlength="2" [required]="isRequired" [(ngModel)]="minutes">
  <mat-error>
    <app-required-error [control]="formControl"></app-required-error>
  </mat-error>
</mat-form-field>
</div>
