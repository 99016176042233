import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import AppConfigService from 'src/app/shared/services/app-config.service';
import { Issuer } from '../issuer.model';
import { CfiCode } from '../cfi-code.model';
import { Country } from '../country.model';
import { Market } from '../market.model';
import { InfringementType } from '../infringement-type.model';
import { StorNotification } from '../stor-notification.model';
import { AppConfig } from '../app-config.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private config: AppConfig;

  constructor(private http: HttpClient, configService: AppConfigService) {
    this.config = configService.appConfig;
  }

  getIssuers() {
    return this.http.get<Issuer[]>(`${this.config.apiUrl}/eui`);
  }

  postStor(stor: any) {
    return this.http.post(`${this.config.apiUrl}/`, stor);
  }

  getCFICodes() {
    return this.http.get<CfiCode[]>(`${this.config.apiUrl}/cficode`);
  }

  getCountries() {
    return this.http.get<Country[]>(`${this.config.apiUrl}/country`);
  }

  getMarkets() {
    return this.http.get<Market[]>(`${this.config.apiUrl}/market`);
  }

  getInfringementTypes() {
    return this.http.get<InfringementType[]>(
      `${this.config.apiUrl}/infringementtype`
    );
  }

  getHistoricalStorNotifications() {
    return this.http.get<StorNotification[]>(`${this.config.apiUrl}/history`);
  }

  getEncryptionKey() {
    return this.http.get<any>(`${this.config.apiUrl}/key`);
  }

  getSummeryFile(storId: string) {
    return this.http.get<any>(`${this.config.apiUrl}/file?storId=${storId}`);
  }
}
