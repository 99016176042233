<section class="content-card">
  <form [formGroup]="form">
    <h3>Details reporter</h3>
    <app-info-dialog title="Position within the reporting entity">
      <p>Position of the natural person in charge of the STOR within the submitting entity.</p>
    </app-info-dialog>
    <mat-form-field>
      <mat-label>Position within the reporting entity</mat-label>
      <input matInput required formControlName="position">
      <mat-error>
        <app-required-error [control]="form.controls['position']"></app-required-error>
        <app-length-error [control]="form.controls['position']"></app-length-error>
      </mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="isLoggedinUserFirstContact">Are you the first contact?</mat-checkbox>

    <section class="content-card" formGroupName="firstContact" *ngIf="!form.get('isLoggedinUserFirstContact')?.value">
      <app-person-form [form]="firstContactGroup"></app-person-form>
    </section>

    <mat-checkbox formControlName="hasSecondContact">Is there a second person?</mat-checkbox>
    <section class="content-card" formGroupName="secondContact" *ngIf="form.get('hasSecondContact')?.value">
      <app-person-form [form]="secondContactGroup"></app-person-form>
    </section>

    <div *ngIf="account">
      <h3>Reporting entity</h3>
      <h4>{{account.name}}</h4>
      <div>{{account.street}} {{account.number}}</div>
      <div>{{account.postalCode}} {{account.city}}</div>
    </div>

    <div>
      <app-info-dialog title="Acting capacity of entity with respect to the orders or transactions that could constitute insider dealing, market
    manipulation or attempted insider dealing or market manipulation">
        Description of the capacity in which the reporting entity was acting with regards to the order(s) or
        transaction(s) that could constitute insider dealing, market manipulation or attempted insider dealing or market
        manipulation, e.g. executing orders on behalf of clients, dealing on own account, operating a trading venue,
        systematic internaliser
      </app-info-dialog>
    </div>
    <mat-form-field>
      <mat-label>Acting capacity of entity</mat-label>
      <input matInput formControlName="actingCapacityOfEntity">
      <mat-error>
        <app-length-error [control]="form.controls['actingCapacityOfEntity']"></app-length-error>
      </mat-error>
    </mat-form-field>

    <app-info-dialog
      title="Type of trading activity (market making, arbitrage etc.) and type of instrument traded (securities, derivatives, etc.) by the reporting entity">
      If available.
    </app-info-dialog>
    <mat-form-field>
      <mat-label>Type of trading activity</mat-label>
      <input matInput formControlName="typeOfTradingActivity">
      <mat-error>
        <app-length-error [control]="form.controls['typeOfTradingActivity']"></app-length-error>
      </mat-error>
    </mat-form-field>

    <app-info-dialog title="Relationship with the ‘suspected person’ in respect of which the STOR is submitted">
      Description of any corporate, contractual or organisational arrangements or circumstances or relationships.
    </app-info-dialog>
    <mat-form-field>
      <mat-label>Relationship with suspect</mat-label>
      <input matInput formControlName="relationShipWithSuspect">
      <mat-error>
        <app-length-error [control]="form.controls['relationShipWithSuspect']"></app-length-error>
      </mat-error>
    </mat-form-field>
  </form>
</section>
