import { Component, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { StorNotification } from '../shared/stor-notification.model';
import { get, set } from 'idb-keyval';
import { ApiService } from '../shared/services/api.service';
import { Router } from '@angular/router';
import { EncryptionService } from '../shared/services/encryption.service';
import { MatPaginator } from '@angular/material/paginator';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../shared/services/user.service';

interface StorTableData {
  stor: StorNotification;
  id: string;
  editable: boolean;
  status: string;
}

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent {
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  dataSource = new MatTableDataSource<StorTableData>();
  displayedColumns: string[] = ['caseNumber', 'notifier', 'registerDate', 'status', 'button'];
  historicalStorNotifications!: StorNotification[]
  summaryFileDownloadFailed = false;
  isLoading = true;

  constructor(private apiService: ApiService,
    private router: Router,
    private encryptionService: EncryptionService,
    private userService: UserService) { }

  async ngAfterViewInit() {
    await get(this.userService.storFormName).then((formStr) => {
      this.encryptionService.decrypt(formStr).then(localForms => {
        for (let key in localForms) {
          let x = localForms[key]
          this.dataSource.data.push({
            id: x.form.id,
            stor: {
              caseNumber: '',
              notifier: this.getNotifier(x.form),
              registerDate: undefined,
              statecode: '0'
            },
            editable: true,
            status: 'Draft',
          })
        }
      })
    });

    let stor = await firstValueFrom(this.apiService.getHistoricalStorNotifications());
    stor.forEach(x => {
      this.dataSource.data.push({ id: '', stor: x, editable: false, status: x.statecode === '1' ? 'Closed' : 'Submitted' });
    })
    this.dataSource.data.sort((a, b) => this.compare(a, b));

    this.dataSource.paginator = this.paginator;

    this.isLoading = false;
  }

  private compare(a: any, b: any): number {
    if (!a.stor.registerDate && !b.stor.registerDate)
      return 0
    if (a.stor.registerDate < b.stor.registerDate)
      return 1
    if (a.stor.registerDate > b.stor.registerDate)
      return -1
    return 0
  }

  edit(row: any) {
    window.location.href = `/stor/edit;id=${row.id}?returnUrl=${encodeURIComponent(this.router.url)}`;
  }

  deleteRow(row: any) {
    const updatedData = this.dataSource.data.filter((i) => i.id !== row.id);
    this.dataSource.data = updatedData;

    get(this.userService.storFormName).then((formStr) => {
      this.encryptionService.decrypt(formStr).then(forms => {
        delete forms[row.id]
        this.encryptionService.encrypt(forms).then(x => {
          set(this.userService.storFormName, x);
        })
      })
    });
  }

  downloadPDF(data: string, name: string) {
    const linkSource = `data:application/pdf;base64,${data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = name;
    downloadLink.click();
  }

  downloadSummary(row: any) {
    this.apiService.getSummeryFile(row.stor.id).subscribe({
      next: result =>
        this.downloadPDF(result.data, result.fileName),
      error: () => this.summaryFileDownloadFailed = true
    });
  }

  formatDate(date: any) {
    let formatedDate = (new Date(date)).toLocaleDateString()
    return formatedDate === 'Invalid Date' ? "" : formatedDate;
  }

  private getNotifier(form: any) {
    if (form.contactInfo.isLoggedinUserFirstContact) {
      let userStr = window.sessionStorage.getItem('afm.portaal.activeUser');
      if (userStr) {
        let user = JSON.parse(userStr);
        return `${user.firstName} ${user.lastName}`
      }
      return '';
    }
    return `${form.contactInfo.firstContact.firstName} ${form.contactInfo.firstContact.lastName}`
  }
}
