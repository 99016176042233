<form [formGroup]="form">
  <h3>Legal Person</h3>

  <div>
    <app-info-dialog title="Name">
      <p>For natural persons: the first name(s) and the last name(s).</p>
      For legal persons: full name including legal form as provided for in the register of the country pursuant to the
      laws of which it is incorporated, if applicable, and Legal Entity Identifier (LEI) code in accordance with ISO
      17442, where applicable
    </app-info-dialog>
  </div>
  <mat-form-field>
    <mat-label>Company name</mat-label>
    <input matInput formControlName="companyName">
    <mat-error>
      <app-required-error [control]="form.controls['companyName']"></app-required-error>
      <app-length-error [control]="form.controls['companyName']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Legal form</mat-label>
    <input matInput formControlName="legalForm">
    <mat-error>
      <app-length-error [control]="form.controls['legalForm']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>LEI number</mat-label>
    <input matInput formControlName="leiNumber">
    <mat-error>
      <app-required-error [control]="form.controls['leiNumber']"></app-required-error>
      <app-length-error [control]="form.controls['leiNumber']"></app-length-error>
    </mat-error>
  </mat-form-field>
</form>
