import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidatorUtil } from 'src/app/util/validator-util';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss']
})
export class ContactInfoComponent implements OnInit, OnDestroy {

  @Input() form!: FormGroup;
  @Input() account: any;
  isLoggedinUserFirstContactChanged!: Subscription;
  hasSecondContactChanged!: Subscription;

  get firstContactGroup(): FormGroup {
    return this.form.controls['firstContact'] as FormGroup;
  }

  get secondContactGroup(): FormGroup {
    return this.form.controls['secondContact'] as FormGroup;
  }

  ngOnInit(): void {
    this.isLoggedinUserFirstContactChanged = this.form.controls['isLoggedinUserFirstContact'].valueChanges.subscribe(e => {
      this.setContactValidators(this.firstContactGroup, !e);
      if (e) this.firstContactGroup.reset();
    });
    this.hasSecondContactChanged = this.form.controls['hasSecondContact'].valueChanges.subscribe(e => {
      this.setContactValidators(this.secondContactGroup as FormGroup, e);
      if (!e) this.secondContactGroup.reset();
    });
    this.form.controls['isLoggedinUserFirstContact'].setValue(this.form.controls['isLoggedinUserFirstContact'].value);
    this.form.controls['hasSecondContact'].setValue(this.form.controls['hasSecondContact'].value);
  }

  ngOnDestroy() {
    this.isLoggedinUserFirstContactChanged.unsubscribe();
    this.hasSecondContactChanged.unsubscribe();
  }

  private setContactValidators(contact: FormGroup, required: boolean) {
    ValidatorUtil.setRequiredValidator(contact.controls['initials'], required);
    ValidatorUtil.setRequiredValidator(contact.controls['lastName'], required);
    ValidatorUtil.setRequiredValidator(contact.controls['position'], required);
    ValidatorUtil.setRequiredValidator(contact.controls['emailAddress'], required);
  }
}
