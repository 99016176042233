import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../shared/services/api.service';
import { StorNotification } from '../shared/stor-notification.model';
import { MatTable } from '@angular/material/table';
import { get, set } from 'idb-keyval';
import { EncryptionService } from '../shared/services/encryption.service';
import { UserService } from '../shared/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild(MatTable) table!: MatTable<any>;

  storItems: StorNotification[] = []
  displayedColumns: string[] = ['caseNumber', 'notifier', 'registerDate', 'concept'];
  historicalStorNotifications!: StorNotification[]
  summaryFileDownloadFailed = false;
  totalStorItems: number = 0;

  get storOverviewUrl(): string {
    return `/stor/overview?returnUrl=${encodeURIComponent(this.router.url)}`;
  }

  get sessionStorage(): Storage {
    return window.sessionStorage;
  }

  constructor(private apiService: ApiService,
    private router: Router,
    private encryptionService: EncryptionService,
    private userService: UserService) { }

  ngAfterViewInit(): void {
    this.apiService.getHistoricalStorNotifications().subscribe(result => {
      this.storItems = result
      this.totalStorItems += this.storItems.length
    });

    get(this.userService.storFormName).then((formStr) => {
      this.encryptionService.decrypt(formStr).then(localForms => {
        if (localForms) {
          this.totalStorItems += Object.keys(localForms).length;
        }
      })
    });
  }
}
