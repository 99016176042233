import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { StorData } from 'src/app/form/stor-data.model';
import { SearchDialogComponent } from 'src/app/shared/search-dialog/search-dialog.component';
import { ValidatorUtil } from 'src/app/util/validator-util';

@Component({
  selector: 'app-suspect-form',
  templateUrl: './suspect-form.component.html',
  styleUrls: ['./suspect-form.component.scss'],
})
export class SuspectFormComponent implements OnInit, OnDestroy {
  @Input() form!: FormGroup;
  @Input() data!: StorData;
  @Output() readonly confirm = new EventEmitter<void>();
  @Output() readonly cancel = new EventEmitter<void>();
  isNaturalPersonChanged!: Subscription;

  get naturalPerson() {
    return this.form.controls['naturalPerson'] as FormGroup;
  }

  get legalPerson() {
    return this.form.controls['legalPerson'] as FormGroup;
  }

  get country() {
    return this.form.controls['country'] as FormControl;
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.isNaturalPersonChanged = this.form.controls[
      'isNaturalPerson'
    ].valueChanges.subscribe((e) => {
      this.enableNaturalPersonForm(this.form, e);
    });
    this.form.controls['isNaturalPerson'].setValue(this.form.controls['isNaturalPerson'].value);
  }

  enableNaturalPersonForm(
    editableSuspect: FormGroup<any> | null,
    isNaturalPerson: boolean
  ) {
    if (isNaturalPerson) {
      editableSuspect?.controls['naturalPerson'].enable();
      editableSuspect?.controls['legalPerson'].disable();
    } else {
      editableSuspect?.controls['naturalPerson'].disable();
      editableSuspect?.controls['legalPerson'].enable();
    }
  }
  ngOnDestroy(): void {
    this.isNaturalPersonChanged.unsubscribe();
  }

  confirmSuspect() {
    this.confirm.emit();
  }

  cancelSuspect() {
    this.cancel.emit();
  }

  openCountrySearchDialog() {
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      data: {
        rows: this.data.countries,
        columns: [
          { name: 'name', label: 'Naam' },
          { name: 'isoAlpha2Code', label: 'Iso 2 code' },
          { name: 'descriptionEn', label: 'Name' },
          { name: 'isoAlpha3Code', label: 'Iso 3 code' },
        ],
        selectedRow: this.country.value,
      },
      width: '75%',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.country.patchValue(result);
    });
  }
}
