<form [formGroup]="form">
  <div class="details">
    <div class="details-content">
      <mat-checkbox formControlName="isNaturalPerson">Natural person?</mat-checkbox>

      <app-natural-person *ngIf="form.controls['isNaturalPerson'].value" [form]="naturalPerson"></app-natural-person>
      <app-legal-person *ngIf="!form.controls['isNaturalPerson'].value" [form]="legalPerson"></app-legal-person>

      <h3>Address</h3>

      <mat-form-field>
        <mat-label>Street</mat-label>
        <input matInput formControlName="street">
        <mat-error>
          <app-length-error [control]="form.controls['street']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Street number</mat-label>
        <input matInput formControlName="streetNumber">
        <mat-error>
          <app-length-error [control]="form.controls['streetNumber']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Street number addition</mat-label>
        <input matInput formControlName="streetNumberAddition">
        <mat-error>
          <app-length-error [control]="form.controls['streetNumberAddition']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Postal Code</mat-label>
        <input matInput formControlName="postalCode">
        <mat-error>
          <app-length-error [control]="form.controls['postalCode']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput formControlName="city">
        <mat-error>
          <app-length-error [control]="form.controls['city']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>State/Province</mat-label>
        <input matInput formControlName="state">
        <mat-error>
          <app-length-error [control]="form.controls['state']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <p>
        <button class="afm-button afm-button-secondary" (click)="openCountrySearchDialog()">Search Country</button>
        <span *ngIf="country">{{country.value?.name}}</span>
      </p>

      <h3>Additional Suspect Information</h3>

      <div>
        <app-info-dialog title="Account number(s)">
          Numbers of the cash and securities account(s), any joint accounts or any Powers of Attorney on the account the
          suspected entity/person holds.
        </app-info-dialog>
      </div>
      <mat-form-field>
        <mat-label>Account Number</mat-label>
        <input matInput formControlName="accountNumber">
        <mat-error>
          <app-length-error [control]="form.controls['accountNumber']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <div>
        <app-info-dialog
          title="Client identifier under transaction reporting pursuant to Regulation (EU) No 600/2014 on markets on financial instruments (or any other code of identification)">
          In case the suspected person is a client of the reporting entity.
        </app-info-dialog>
      </div>
      <mat-form-field>
        <mat-label>Client Identifier</mat-label>
        <input matInput formControlName="clientIdentifier">
        <mat-error>
          <app-length-error [control]="form.controls['clientIdentifier']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <div>
        <app-info-dialog
          title="Relationship with the issuer of the financial instruments concerned (where applicable and where known)">
          Description of any corporate, contractual or organisational arrangements or circumstances or relationships
        </app-info-dialog>
      </div>
      <mat-form-field>
        <mat-label>Relationship with the user of the financial instrument</mat-label>
        <input matInput formControlName="relationship">
        <mat-error>
          <app-length-error [control]="form.controls['relationship']"></app-length-error>
        </mat-error>
      </mat-form-field>

      <button class="afm-button" variant="primary" [disabled]="!form.valid" (click)="confirmSuspect()">Confirm</button>
      <button class="afm-button afm-button-secondary" variant="primary" (click)="cancelSuspect()">Cancel</button>
    </div>
  </div>
</form>
