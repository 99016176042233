import { Injectable, OnInit } from '@angular/core';
import { ApiService } from './api.service';
import * as CryptoJS from 'crypto-js';
import { Observable, firstValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  encryptionKey: any

  constructor(private apiService: ApiService) {
  }

  async getKey() {
    if (this.encryptionKey)
      return
    this.encryptionKey = await firstValueFrom(this.apiService.getEncryptionKey());
  }

  encrypt(value: any) {
    return new Promise<string>(async (resolve) => {
      if (!value) {
        resolve('');
        return;
      }

      await this.getKey();

      let json = JSON.stringify(value)
      let wordArry = CryptoJS.enc.Utf8.parse(json)
      let encryptedValue = CryptoJS.AES.encrypt(wordArry, this.encryptionKey.key).toString();
      resolve(encryptedValue);
    })
  }

  decrypt(value: any) {
    return new Promise<any>(async (resolve) => {
      if (!value) {
        resolve(null);
        return
      }

      await this.getKey();

      let resultStr = CryptoJS.AES.decrypt(value, this.encryptionKey.key).toString(CryptoJS.enc.Utf8);
      if (!resultStr) {
        resolve(null);
        return
      }

      resolve(JSON.parse(resultStr));
    })
  }
}
