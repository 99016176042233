import { bootstrap } from '@angular-architects/module-federation-tools';
import { AppModule } from './app/app.module';
import { setBaseRef } from './app/util/mfeUtils';
export function initializeApp(baseUrl:string) {
  setBaseRef(baseUrl);
  loadStyle(baseUrl);
}

function CSSDone() {
  bootstrap(AppModule, { production: true, appType: 'microfrontend' }).catch(
    (err) => console.error(err)
  );
}

function loadStyle(baseRef:string) {
  const head = document.getElementsByTagName('head')[0];
  const style = document.createElement('link');
  style.id = 'storStyle';
  style.rel = 'stylesheet';
  style.href = `${baseRef}styles.css`;
  style.onload = CSSDone;
  head.appendChild(style);
}
