<form [formGroup]="form">
  <button class="afm-button afm-button-secondary" (click)="openCountrySearchDialog()">Country</button>
  <p><span *ngIf="country.value">{{country.value.name}}</span></p>

  <button class="afm-button afm-button-secondary" (click)="openMarketSearchDialog()">Market</button>
  <p><span *ngIf="market.value">{{market.value.name}}</span></p>

  <button class="afm-button" variant="primary" [disabled]="!form.valid"
    (click)="confirmMarket()">Confirm</button>
  <button class="afm-button afm-button-secondary" (click)="cancelMarket()">Cancel</button>
</form>
