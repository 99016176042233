<h1 mat-dialog-title>Search dialog</h1>
<mat-dialog-content>
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input>
  </mat-form-field>

  <div>
    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <ng-container *ngFor="let column of columns">
        <ng-container [matColumnDef]="column.name">
          <mat-header-cell *matHeaderCellDef>{{column.label}}</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row[column.name]}} </mat-cell>
        </ng-container>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row class="selected-row" [class.selected-row]="selectedRow === row" (click)="selectedRow = row"
        *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </mat-table>
  </div>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button class="afm-button" [mat-dialog-close]="selectedRow" variant="primary">Ok</button>
  <button class="afm-button afm-button-secondary"(click)="dialog.close()">Cancel</button>
</mat-dialog-actions>
