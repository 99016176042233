import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Market } from '../market.model';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent {
  dataSource = new MatTableDataSource();
  displayedColumns!: string[];
  selectedRow: any;
  columns: any[];

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  constructor(
    public dialog: MatDialogRef<SearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.dataSource = new MatTableDataSource(data.rows);
    this.selectedRow = data.selectedRow;
    this.columns = data.columns;
    this.displayedColumns = this.columns.map(x => { return x.name });
  }

  selectRow(row: any) {
    this.selectRow = row;
  }
}
