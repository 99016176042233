import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-natural-person',
  templateUrl: './natural-person.component.html',
  styleUrls: ['./natural-person.component.scss']
})
export class NaturalPersonComponent {
@Input() form!: FormGroup;

//this is needed because of CRM where the timezone is ignored for the field.
fixDate(date: Date) {
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const userTimezoneOffset = newDate.getTimezoneOffset() * 60000;
    const finalDate: Date = new Date(newDate.getTime() - userTimezoneOffset);
    this.form.controls['dateOfBirth'].patchValue(finalDate.toISOString());
}
}
