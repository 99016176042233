<section class="content-card">
  <form [formGroup]="form">
    <div>
      <app-info-dialog title="Nature of the suspicion">
        Specify the type of breach the reported orders or transactions could constitute:
        <ul>
          <li>market manipulation,</li>
          <li>insider dealing,</li>
          <li>attempted market manipulation,</li>
          <li>attempted insider dealing</li>
          <li>other (please specify).</li>
        </ul>
      </app-info-dialog>
    </div>
    <button class="afm-button" [class.afm-button-secondary]="!isInfringementTypeRequired" variant="primary"
      (click)="openInfringementTypeSearchDialog()">Nature of
      suspicion{{isInfringementTypeRequired ? '*' : ''}}</button>
    <p *ngIf="infringementType.value">{{infringementType.value.name}}</p>
    <mat-error>
      <ng-container *ngIf="infringementType.touched && infringementType.errors?.['required']">You must choose an
        option</ng-container>
    </mat-error>

    <mat-checkbox formControlName="infringementTypeFound">Nature of Suspicion found?</mat-checkbox>
    <mat-form-field *ngIf="!form.get('infringementTypeFound')?.value">
      <mat-label>Please specify other nature of suspicion</mat-label>
      <input matInput formControlName="otherInfringementType">
      <mat-error>
        <app-required-error [control]="form.controls['otherInfringementType']"></app-required-error>
        <app-length-error [control]="form.controls['otherInfringementType']"></app-length-error>
      </mat-error>
    </mat-form-field>

    <div>
      <app-info-dialog title="Reasons for the suspicion">
        <p>Description of the activity (transactions and orders, way of placing the orders or executing the transaction and
        characteristics of the orders and transactions that make them suspicious) and how the matter came to the
        attention of the reporting person, and specify the reasons for suspicion.</p>
        As non-exhaustive guiding criteria, the description may include:
        <li>for financial instruments admitted to trading on/traded on a trading venue, a description of the nature of
          order book interaction/transactions that could constitute insider dealing, market manipulation or attempted
          insider dealing or market manipulation,</li>
        <li>for OTC derivatives, details concerning transactions or orders placed in the underlying asset and
          information on any possible link between dealings in the cash market of the underlying asset and the reported
          dealings in the OTC derivative.</li>
      </app-info-dialog>
    </div>
    <mat-form-field>
      <mat-label>Reasons for the suspicion</mat-label>
      <textarea matInput formControlName="reasonForSuspicion"></textarea>
      <mat-error>
        <app-required-error [control]="form.controls['reasonForSuspicion']"></app-required-error>
        <app-length-error [control]="form.controls['reasonForSuspicion']"></app-length-error>
      </mat-error>
    </mat-form-field>
  </form>
</section>
