import {Injectable } from '@angular/core';
import { AuthorisationTypeCode } from '../authorisation-type-code.enum';
import { UserService } from './user.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
	constructor(private userService: UserService) {}

  static getAuthToken(){
    return window.sessionStorage.getItem('msal.idtoken') ?? "";
  }

	isAuthorisedFor(
		code: AuthorisationTypeCode
	): boolean {
		if (!code) {
			return true;
		}
    let activeAccount = this.userService.accountPermissions.find(x => x.AccountId === this.userService.account.id)
    if (activeAccount)
      return activeAccount.Resources.some(x => x.Code === code);

    return false
	}
}
