import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";

export class ValidatorUtil {
  static setRequiredValidator(formControl: AbstractControl, required: boolean) {
		this.addOrRemoveValidator(formControl, required, Validators.required);
	}

	static addOrRemoveValidator(formControl: AbstractControl, required: boolean, validator: ValidatorFn | ValidatorFn[]) {
		if (required) {
			formControl.addValidators(validator);
		} else {
			formControl.removeValidators(validator);
		}
		formControl.updateValueAndValidity({ emitEvent: false });
	}
}
