import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { StorData } from 'src/app/form/stor-data.model';

@Component({
  selector: 'app-identity-of-suspect',
  templateUrl: './identity-of-suspect.component.html',
  styleUrls: ['./identity-of-suspect.component.scss'],
})
export class IdentityOfSuspectComponent implements OnInit {
  @ViewChild('suspectsTable') suspectsTable!: MatTable<any>;
  @Input() formArray!: FormArray;
  @Input() data!: StorData;
  suspectsTableColumns = ['name', 'clientIdentifier', 'country', 'button'];
  editableSuspect: FormGroup | null = null;
  oldSuspectValue: any;

  get suspects(): FormArray {
    return this.formArray;
  }

  constructor(private formBuilder: FormBuilder, public dialog: MatDialog) {}
  ngOnInit() {
    this.formArray.controls.forEach((x) => {
      const suspect = x as FormGroup;
      if (suspect.controls['isNaturalPerson']) {
        suspect.controls['naturalPerson'].enable();
        suspect.controls['legalPerson'].disable();
      } else {
        suspect.controls['naturalPerson'].disable();
        suspect.controls['legalPerson'].enable();
      }
    });
  }

  editSuspect(i: number) {
    this.editableSuspect = this.suspects.at(i) as FormGroup;
    this.oldSuspectValue = this.suspects.at(i).value;
  }

  deleteSuspect(i: number) {
    this.suspects.removeAt(i);
    this.suspectsTable.renderRows();
  }

  confirmSuspect() {
    if (!this.suspects.controls.some((x) => x == this.editableSuspect)) {
      this.suspects.push(this.editableSuspect);
    }
    this.editableSuspect = null;
    this.suspectsTable.renderRows();
  }

  cancelSuspect() {
    this.editableSuspect?.patchValue(this.oldSuspectValue);
    this.editableSuspect = null;
  }

  newSuspect() {
    this.editableSuspect = newSuspect(this.formBuilder);
  }
}

export function newSuspect(formBuilder: FormBuilder, value: any = null) {
  let group: FormGroup = formBuilder.group({
    isNaturalPerson: true,
    naturalPerson: formBuilder.group({
      initials: ['', [Validators.maxLength(20), Validators.required]],
      firstName: ['', Validators.maxLength(200)],
      lastNamePrefix: ['', Validators.maxLength(20)],
      lastName: ['', [Validators.maxLength(200), Validators.required]],
      dateOfBirth: [null, Validators.required],
      nationalIdentificationNumber: ['', Validators.maxLength(80)],
      employer: ['', Validators.maxLength(255)],
      position: ['', Validators.maxLength(255)],
    }),
    legalPerson: formBuilder.group({
      companyName: ['', [Validators.maxLength(255), Validators.required]],
      legalForm: ['', Validators.maxLength(100)],
      leiNumber: ['', [Validators.maxLength(20), Validators.required]],
    }),
    street: ['', Validators.maxLength(250)],
    streetNumber: ['', Validators.maxLength(250)],
    streetNumberAddition: ['', Validators.maxLength(250)],
    postalCode: ['', Validators.maxLength(20)],
    city: ['', Validators.maxLength(100)],
    state: ['', Validators.maxLength(50)],
    country: null,
    accountNumber: ['', Validators.maxLength(255)],
    clientIdentifier: ['', Validators.maxLength(255)],
    relationship: ['', Validators.maxLength(255)],
  });

  if (value) {
    group.patchValue(value);
  }

  return group;
}
