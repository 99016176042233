import { FormControl, Validators } from '@angular/forms';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-datetime-control',
  templateUrl: './datetime-control.component.html',
  styleUrls: ['./datetime-control.component.scss'],
  providers: []
})
export class DatetimeControlComponent implements OnInit, OnDestroy {
  @Input() formControl!: FormControl
  @Input() dateLabel: string = "";
  @Input() timeLabel: string = "";
  formControlChanged!: Subscription;

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  _hours: number = 0;
  get hours() {
    return this._hours;
  }
  set hours(value) {
    if (value > 23)
      this._hours = 23;
    else if
      (value < 0) this._hours = 0;
    else if (value == null)
      this._hours = 0;
    else
      this._hours = value;

    this.updateForm();
  }

  _minutes: number = 0;
  get minutes() {
    return this._minutes;
  }
  set minutes(value) {
    if (value > 59)
      this._minutes = 59;
    else if (value < 0)
      this._minutes = 0;
    else if (value == null)
      this._minutes = 0;
    else
      this._minutes = value;

    this.updateForm();
  }

  _date: Date | null = null;
  get date() {
    return this._date
  }
  set date(value: Date | null) {
    if (value) {
      this._date = value;
    }
    this.updateForm();
  }

  ngOnInit() {
    this.formChanged();
    this.formControlChanged = this.formControl.valueChanges.subscribe(_ => {
      this.formChanged();
    });
  }
  private formChanged() {
    if (this.formControl.value !== null) {
      let date = new Date(this.formControl.value)
      this.hours = date.getHours();
      this.minutes = date.getMinutes();
      this.date = date;
    }
  }

  ngOnDestroy() {
    this.formControlChanged.unsubscribe();
  }

  private updateForm() {
    if (this.minutes != null && this.hours != null && this.date) {
      let date = new Date(0, 0, 0, 0, 0);
      date.setUTCDate(this.date.getDate());
      date.setUTCMonth(this.date.getMonth());
      date.setUTCFullYear(this.date.getFullYear());
      date.setHours(this.hours);
      date.setMinutes(this.minutes);
      this.formControl.patchValue(date, { emitEvent: false });
    }
  }
}
