<section class="content-section">
  <h2>Confirmation</h2>
  <div class="confirmation-text">
    <p>Bedankt voor uw melding. U ontvangt binnen enkele minuten een bevestiging per e-mail, hierin is een link
      opgenomen
      om een kopie van uw melding te downloaden voor uw eigen administratie.</p>

    <p>Heeft u nog vragen, neem dan contact op met de STORdesk van de AFM via <a href='mailto://STORdesk@afm.nl'>STORdesk@afm.nl</a> of <a href='tel://+0207973716'>020 797 3716</a>.</p>

    <p>Thank you for your notification. Within a few minutes you will receive a confirmation by email. This email
      contains
      a link through which you can find your notification in a downloadable format.</p>

    <p>If you have any further questions, please contact the STORdesk via <a href='mailto://STORdesk@afm.nl'>STORdesk@afm.nl</a> or <a href='tel://+31207973716'>+31 20 797 3716</a></p>
  </div>
</section>
