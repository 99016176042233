<form [formGroup]="form">

  <div class="financialinstrument-info-dialog">
    <app-info-dialog title="Description of the financial instrument">
      Describe the financial instrument which is the subject of the STOR, specifying:
      <ol type="a">
        <li>the full name or description of the financial instrument,</li>
        <li>the instrument identifier code as defined in a Commission Delegated Regulation adopted under Article 26 of
          Regulation (EU) No 600/2014, when applicable, or other codes (e.g. ISIN code), </li>
        <li>the type of financial instrument according to the taxonomy used to classify the financial instrument and the
          associated code (ISO 10962 CFI code).</li>
      </ol>
    </app-info-dialog>
  </div>
  <button class="afm-button afm-button-secondary" (click)="openIssuerSearchDialog()">Issuer</button>
  <p><span *ngIf="issuer.value">{{issuer.value.name}}, {{issuer.value.registeredOffice}}</span></p>

  <mat-checkbox formControlName="issuerFound">Issuer found in list?</mat-checkbox>
  <mat-form-field *ngIf="!form.get('issuerFound')?.value">
    <mat-label>Other Issuer</mat-label>
    <input matInput formControlName="otherIssuer">
    <mat-error>
      <app-required-error [control]="form.controls['otherIssuer']"></app-required-error>
      <app-length-error [control]="form.controls['otherIssuer']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>ISIN</mat-label>
    <input matInput formControlName="isin">
    <mat-error>
      <app-length-error [control]="form.controls['isin']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <button class="afm-button afm-button-secondary" (click)="openCFISearchDialog()">CFI code</button>
  <p><span *ngIf="cfiCode.value">{{cfiCode.value.name}}, {{cfiCode.value.registeredOffice}}</span></p>

  <mat-checkbox formControlName="cfiCodeFound">CFI Code found?</mat-checkbox>
  <mat-form-field *ngIf="!form.get('cfiCodeFound')?.value">
    <mat-label>Description other CFI</mat-label>
    <input matInput formControlName="descriptionOtherCFI">
    <mat-error>
      <app-required-error [control]="form.controls['descriptionOtherCFI']"></app-required-error>
      <app-length-error [control]="form.controls['descriptionOtherCFI']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>CFI code attributes</mat-label>
    <input matInput formControlName="cfiCodeAttributes">
    <mat-error>
      <app-length-error [control]="form.controls['cfiCodeAttributes']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <div>
    <app-info-dialog title="Additional elements for orders and transactions relating to OTC derivatives">
      (The list of data below is not exhaustive)
      <ol type="a">
        <li>identify the type of OTC derivative (e.g. contracts for difference (CFD), swaps, credit default swaps (CDS)
          and over-the-counter (OTC) options) using the types referred to in Article 4(3)(b) of Commission Implementing
          Regulation (EU) No 1247/2012.</li>
        <li>describe the characteristics of the OTC derivative including at least, where relevant to the particular
          derivative type, the following:</li>
        <ul>
          <li>nominal amount (face value),</li>
          <li>currency of the price denomination,</li>
          <li>maturity date,</li>
          <li>premium (price),</li>
          <li>interest rate.</li>
        </ul>
        <li>describe at the least the following, where relevant for the particular type of OTC derivative:</li>
        <ul>
          <li>margin, up-front payment and nominal size or value of underlying financial instrument,</li>
          <li>transaction terms such as the strike price, the contract terms (e.g. spread bet gain/loss per tick move).
          </li>
        </ul>
        <li>describe the underlying financial instrument of the OTC derivative specifying:</li>
        <ul>
          <li>the full name of the underlying financial instrument or description of the financial instrument,</li>
          <li>the instrument identifier code as defined under Commission Delegated Regulation to be adopted under
            Article
            26 of Regulation (EU) No 600/2014 when applicable, or other codes,</li>
          <li>the type of financial instrument according to the taxonomy used to classify the financial instrument and
            the
            associated code (ISO 10962 CFI code).</li>
        </ul>
      </ol>
    </app-info-dialog>
  </div>

  <mat-checkbox formControlName="otc">OTC</mat-checkbox>

  <mat-form-field *ngIf="form.get('otc')?.value">
    <mat-label>OTC characteristics</mat-label>
    <input matInput formControlName="otcCharacteristics">
    <mat-error>
      <app-required-error [control]="form.controls['otcCharacteristics']"></app-required-error>
      <app-length-error [control]="form.controls['otcCharacteristics']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Additional information</mat-label>
    <input matInput formControlName="additionalInformation">
    <mat-error>
      <app-length-error [control]="form.controls['additionalInformation']"></app-length-error>
    </mat-error>
  </mat-form-field>

  <button class="afm-button" [disabled]="!form.valid" (click)="confirmFinancialInstrument()">Confirm</button>
  <button class="afm-button afm-button-secondary" (click)="cancelFinancialInstrument()">Cancel</button>
</form>
