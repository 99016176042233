import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './form/form.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContactInfoComponent } from './form/steps/step1/contact-info.component';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TransactionComponent } from './form/steps/step2/transaction.component';
import { FinancialInstrumentComponent } from './form/steps/step2/financial-instrument/financial-instrument.component';
import { SharedModule } from './shared/shared.module';
import { PersonFormComponent } from './form/steps/step1/person-form/person-form.component';
import AppConfigService from 'src/app/shared/services/app-config.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MarketComponent } from './form/steps/step2/market/market.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { NatureOfSuspicionComponent } from './form/steps/step3/nature-of-suspicion.component';
import { IdentityOfSuspectComponent } from './form/steps/step4/identity-of-suspect.component';
import { SuspectFormComponent } from './form/steps/step4/suspect-form/suspect-form.component';
import { NaturalPersonComponent } from './form/steps/step4/natural-person/natural-person.component';
import { LegalPersonComponent } from './form/steps/step4/legal-person/legal-person.component';
import { AdditionalInformationComponent } from './form/steps/step5/additional-information.component';
import { DocumentationAttachedComponent } from './form/steps/step6/documentation-attached.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ConfirmationComponent } from './form/confirmation/confirmation.component';
import { MatTableModule } from '@angular/material/table';
import { AfmPortaalCommonModule } from 'afm-portaal-common';
import {MatMenuModule} from '@angular/material/menu';
import { OverviewComponent } from './overview/overview.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

const DUTCH_DATE_FORMAT = {
  parse: {
      dateInput: 'DD-MM-YYYY',
  },
  display: {
      dateInput: 'DD-MM-YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    DashboardComponent,
    ContactInfoComponent,
    TransactionComponent,
    FinancialInstrumentComponent,
    PersonFormComponent,
    MarketComponent,
    NatureOfSuspicionComponent,
    IdentityOfSuspectComponent,
    SuspectFormComponent,
    NaturalPersonComponent,
    LegalPersonComponent,
    AdditionalInformationComponent,
    DocumentationAttachedComponent,
    ConfirmationComponent,
    OverviewComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatStepperModule,
    SharedModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    HttpClientModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatMenuModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    AfmPortaalCommonModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (config: AppConfigService) => config.init,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
    { provide: MAT_DATE_FORMATS, useValue: DUTCH_DATE_FORMAT },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
